import { PopupModal } from 'react-calendly';
import { PrefilledCalendly } from '../header/header';


type Props = {
    showPopup: boolean;
    setShowPopup: (value: boolean) => void;
    prefillValues: PrefilledCalendly
}

const CalendlyPopup = ({ showPopup, setShowPopup, prefillValues }: Props) => {


    let utm = {
        utmCampaign: 'Merito',
        utmContent: '',
        utmMedium: '',
        utmSource: 'Website',
        utmTerm: ''
    }
    return (<PopupModal
        url="https://calendly.com/abhishek-click-merito/30min?hide_gdpr_banner=1"
        utm={utm}
        onModalClose={() => setShowPopup(false)}
        open={showPopup}

        rootElement={document.getElementById("root")}
        pageSettings={{
            backgroundColor: 'f0eff4',
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: '100828',
            textColor: '100828'
        }}
        prefill={{ name: prefillValues.name, email: prefillValues.email }}
    />)
}


export default CalendlyPopup;