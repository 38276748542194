import styles from './ThirdSection.module.scss';



const ThirdSection = () => {


    return (
        <section className={styles.main}>
            <div className={styles.container}>
                <div className={styles.titleSection}>
                    <div className={styles.title}>
                        Ready-made dashboards that help you spot actionable trends in your brand’s performance

                        {/* Give your customers intelligent platform
                        <br />
                        analytics through collaborative dashboards. */}
                    </div>

                    <div className={styles.subTitle}>
                        Designed to ensure that you hit your targets.
                    </div>
                </div>
                <div className={styles.subTitleListContainer}>
                    <ul className={styles.subTitleList}>
                        <li className={styles.listPoint}>Go live in 20 minutes.</li>
                        <li className={styles.listPoint}>Make smarter product marketing decisions.</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default ThirdSection