import styles from "./HeroSection.module.scss";

const HeroSection = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>
          Actionable reports that <br /> boost ROAS and repeat customer rate.
          {/* Ready-made data dashboards
                    <br />
                    for your enterprise customers. */}
        </h1>

        <div className={styles.subtitle}>Your Data Super App.</div>
      </div>
    </section>
  );
};

export default HeroSection;
