import GridCard from './cards/GridCard';
import styles from './SixthSection.module.scss';
import DashboardImg from '../../assets/images/InteractiveDashboard.svg';
import Reports from '../../assets/images/CustomReports.svg';
import AnalyticsEngine from '../../assets/images/AnalyticsEngine.svg';


const CardText = [<>Single portal for all client campaigns</>, <>Improve client satisfaction</>, <>Save time and costs</>]

const SixthSection = () => {


    return (
        <section className={styles.main}>
            <div className={styles.container}>
                <div className={styles.textContainer}>
                    How does Merito help agencies <br></br>
                    that work with D2C brands?

                </div>
                <div className={styles.cardGrid}>
                    <GridCard imgAsset={DashboardImg} text={CardText[0]} />
                    <GridCard imgAsset={Reports} text={CardText[1]} />
                    <GridCard imgAsset={AnalyticsEngine} text={CardText[2]} />
                </div>
                <div className={styles.bottomText}>
                    Become a Tech-First Agency
                </div>
            </div>
        </section>
    );
}

export default SixthSection;