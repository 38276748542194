export const reqiredValidation = (values, label) => {
  if (!values) {
    return `Required ${label}`;
  }
};

export const customEmailValidation = (values) => {
  let pattern =
    /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.in)(?!aol.com)(?!live.com)(?!outlook.com)(?!zoho.com)(?!icloud.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/gm;
  if (!values) {
    return `Required Email Address`;
  } else if (!pattern.test(values)) {
    return "Please use business email ID";
  }
};

export const domainNameValidation = (values, label) => {
  let pattern =
    /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
  if (!values) {
    return `Required ${label}`;
  } else if (!pattern.test(values)) {
    return `Incorrect ${label}. eg: clickmerito.com`;
  }
};

export const mobileNumberValidation = (values) => {
  let pattern = /^[0-9]{10}/gm;
  if (!values) {
    return `Required Mobile Number`;
  } else if (!pattern.test(values)) {
    return "Enter a valid mobile number";
  }
};
