import Header from "../components/header/header";
import { Outlet } from 'react-router-dom'
import Footer from "../components/Footer/Footer";



const Layout = () => {



    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
}

export default Layout