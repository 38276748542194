import styles from "./header.module.scss";
import MeritoDarkLogo from "../../assets/images/MeritoLogoDark.svg";
import Buttons, { ButtonStyles } from "../buttons/Buttons";
import useScrollDirection from "./useScrollDirection";
import { useEffect, useState } from "react";
import CalendlyPopup from "../calendlyPopup/CalendlyPopup";
import EarlyAccess from "../EarlyAccess/EarlyAccess";
import { useNavigate, useSearchParams } from "react-router-dom";

export type PrefilledCalendly = {
  name: string;
  email: string;
};

const Header = () => {
  const scrollDirection = useScrollDirection();
  const [showCalendly, setShowCalendly] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [prefillAnswer, setPrefillAnswer] = useState<PrefilledCalendly>({
    name: "",
    email: "",
  });
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("search params", searchParams);
    let popupValue = searchParams.get("popup");
    if (popupValue == "earlyaccess") {
      setOpenModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // console.log("scroll direction", scrollDirection);

  const handleSave = (values) => {
    // console.log(values);
    setPrefillAnswer({
      name: `${values["firstName"]} ${values["lastName"]}`,
      email: `${values["emailId"]}`,
    });
    setOpenModal(false);
    setShowCalendly(true);
  };

  return (
    <nav
      className={`${styles.header} ${
        scrollDirection === "down" ? styles.hide : ""
      }`}
    >
      <div
        className={styles.brandContainer}
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={MeritoDarkLogo} />
      </div>

      <div className={styles.rightContainer}>
        <Buttons
          type={ButtonStyles.SECONDARY}
          onClick={() => setOpenModal(true)}
        >
          <div className={styles.btnContainer}>Get Early Access</div>
        </Buttons>
        <EarlyAccess
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          handleSave={handleSave}
          isOpen={openModal}
          handleCloseModal={handleCloseModal}
          setIsOpen={setOpenModal}
        />
        <CalendlyPopup
          showPopup={showCalendly}
          setShowPopup={setShowCalendly}
          prefillValues={prefillAnswer}
        />
      </div>
    </nav>
  );
};

export default Header;
