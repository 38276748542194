import styles from "./SecondSection.module.scss";
import ArrowImg from "../../assets/images/Arrow.svg";

const SecondSection = () => {
  return (
    <section className={styles.main}>
      <div className={styles.gridContainer}>
        <div className={styles.subTitle}>
          So how many channels does your brand sell on?
        </div>
        <div className={styles.largeTitle}>
          Do you enjoy merging <br /> data from all of them to <br></br> build a
          report?
        </div>
        <div className={styles.bottomTitleContainer}>
          <div className={styles.title}>WE'VE GOT SOMETHING FOR YOU</div>
          <div className={styles.arrowImgContainer}>
            <img src={ArrowImg} alt="" />
          </div>
        </div>
      </div>
      {/* <div className={styles.gridContainer}>
        <div className={styles.gridOne}>
          <div className={styles.columns}></div>
          <div className={styles.columns}></div>
        </div>
        <div className={styles.gridTwo}>
          <div className={styles.textSection}>

            <div className={styles.subtitle}>
              So how many apps does your brand use?
     
            </div>
          </div>

          <div className={styles.bottomTitleContainer}>
            <div className={styles.title}>
              Do you enjoy merging data from <br />
              all of them to build a report?
            </div>
            <div className={styles.arrowImgContainer}>
              <img src={ArrowImg} alt="" />
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default SecondSection;
