import styles from './SwiperCard.module.scss';

type Props = {
    title: React.ReactNode,
    subtitle: React.ReactNode,
    imgAsset: any
}

const SwiperCard = ({ title, subtitle, imgAsset }: Props) => {


    return (
        <div className={styles.cardContainer}>
            {/* <div className={styles.imgContainer}>
                <img src={imgAsset} />
            </div> */}
            <div className={styles.textContainer}>
                <div className={styles.title}>
                    {title}
                </div>
                <hr />
                <div className={styles.subTitle}>
                    {subtitle}
                </div>
            </div>
        </div>
    );
}

export default SwiperCard;